import styled from "@emotion/styled";
import Card from '../../ui/Card/Card';
import cards from '../../cards';

const Container = styled.div`
display: flex;
justify-content: center;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(${p => p.cols || 8}, 1fr);
    grid-gap: 14px;
    max-width: 1000px;
    margin: 0 auto;
`;
const Item = styled.div`

`;

const CardsGrid = () => {
    return (
        <Container>
        <Grid>
            {cards.map(card => {
                return (
                <Card {...card} shown={true} type="listCard" />
                )
            })}
        </Grid>
        </Container>
    );
}

export default CardsGrid;