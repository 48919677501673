import Adhiraj from './images/kids_photos/Adhiraj.JPG';
import Anna from './images/kids_photos/Anna.JPG';
import Arnold from './images/kids_photos/Arnold.JPG';
import Bobby from './images/kids_photos/Bobby.JPG';
import Charlie from './images/kids_photos/Charlie.JPG';
import Elizabeth from './images/kids_photos/Elizabeth.JPG';
import Emory from './images/kids_photos/Emory.JPG';
import George from './images/kids_photos/George.JPG';
import Grace from './images/kids_photos/Grace.JPG';
import Graydon from './images/kids_photos/Graydon.JPG';
import Johnny from './images/kids_photos/Johnny.JPG';
import Joshua from './images/kids_photos/Joshua.JPG';
import Juliette from './images/kids_photos/Juliette.JPG';
import Kaitlyn from './images/kids_photos/Kaitlyn.JPG';
import Kiara from './images/kids_photos/Kiara.JPG';
import Kristina from './images/kids_photos/Kristina.JPG';
import Luna from './images/kids_photos/Luna.JPG';
import Olek from './images/kids_photos/Olek.JPG';
import Oliver from './images/kids_photos/Oliver.JPG';
import Rebeca from './images/kids_photos/Rebeca.JPG';
import Roselyn from './images/kids_photos/Roselyn.JPG';
import Rowan from './images/kids_photos/Rowan.JPG';
import Sky from './images/kids_photos/Sky.JPG';
import Zane from './images/kids_photos/Zane.JPG';

const cards = [
    {id: 1, img: Adhiraj, name: 'Adhiraj'},
    {id: 2, img: Anna, name: 'Anna'},
    {id: 3, img: Arnold, name: 'Arnold'},
    {id: 4, img: Bobby, name: 'Bobby'},
    {id: 5, img: Charlie, name: 'Charlie'},
    {id: 6, img: Elizabeth, name: 'Elizabeth'},
    {id: 7, img: Emory, name: 'Emory'},
    {id: 8, img: George, name: 'George'},
    {id: 9, img: Grace, name: 'Grace'},
    {id: 10, img: Graydon, name: 'Graydon'},
    {id: 11, img: Johnny, name: 'Johnny'},
    {id: 12, img: Joshua, name: 'Joshua'},
    {id: 13, img: Juliette, name: 'Juliette'},
    {id: 14, img: Kaitlyn, name: 'Kaitlyn'},
    {id: 15, img: Kiara, name: 'Kiara'},
    {id: 16, img: Kristina, name: 'Kristina'},
    {id: 17, img: Luna, name: 'Luna'},
    {id: 18, img: Olek, name: 'Olek'},
    {id: 19, img: Oliver, name: 'Oliver'},
    {id: 20, img: Rebeca, name: 'Rebeca'},
    {id: 21, img: Roselyn, name: 'Roselyn'},
    {id: 22, img: Rowan, name: 'Rowan'},
    {id: 23, img: Sky, name: 'Sky'},
    {id: 24, img: Zane, name: 'Zane'},
  ];

export default cards;