import styled from "@emotion/styled"
import { useContext } from "react";
import { StateContext } from "../../App";
import moment from 'moment';


import ImgCornerBgLeft from '../../images/corner-left-bg-1.svg'
import ImgCornerBgRight from '../../images/corner-right-bg-1.svg'
import ImgCornerLeftInactive from '../../images/corner-left-1.svg'
import ImgCornerLeftActive from '../../images/corner-left-3.svg'
import ImgCornerRightInative from '../../images/corner-right-1.svg'
import ImgCornerRightActive from '../../images/corner-right-3.svg'



const Style = styled.div`
    color: #fff;
    /* background-image: linear-gradient(to bottom, #33ccff, #6633ff); */
    line-height: 36px;

    /* box-shadow: 0 0 0 8px #dcd2d1, 0 2px 4px 0 rgba(0, 0, 0, 0.25); */
    display: flex;
    align-items: flex-end;
    font-size: 18px;


    align-self: flex-end;

    xborder-top: 8px solid #dcd2d1;

    
`;

const Button = styled.div`
    width: 70px;
    text-align: center;
    color: #fff;
    border-right: 1px solid #fff;
    border-top: 6px solid #f0eaea;
    position: relative;

    &:last-child {
        border-right: none;
    }
    
    ${p => p.first ? `
        width: 60px;
        padding-right: 10px;
    `:null}
    ${p => p.last ? `
        border-right: none;
        width: 60px;
        padding-left: 10px;
    `:null}


    ${({status}) => {
        switch(status){
            case 'not-started':
                return `
                    background-image: linear-gradient(to bottom, #d3c2b5, #aa9079 98%);
                `
            case 'finished':
                return `
                    background-image: linear-gradient(to bottom, #d3c2b5, #aa9079 98%);
                    color: rgba(255,255,255,0.7);
                `
            default:
                return `
                    background-image: linear-gradient(to bottom, #33ccff, #6633ff);
                `

        }
    }}
`;


/*
matchGroups: [
    {
      status: 'not-started', 'partial', 'completed'
      elapsed: 0,
      matchedIDs: [],
      remainingMatchIDs: [],
      possibleMatchCount: 0,
      attempts: 0,
    }
],
*/





//   import ImgCornerBgLeft from '../../images/corner-left-bg-1.svg'
//   import ImgCornerBgRight from '../../images/corner-right-bg-1.svg'
//   import ImgCornerLeftInative from '../../images/corner-left-1.svg'
//   import ImgCornerLeftActive from '../../images/corner-left-3.svg'
//   import ImgCornerRightInative from '../../images/corner-right-1.svg'
//   import ImgCornerRightActive from '../../images/corner-right-3.svg'

const CornerStyle = styled.div`
    width: 48px;
    height: 48px;
    background-position: right -12px bottom;
    background-repeat: no-repeat;
    background-image: url(${ImgCornerBgLeft});
    ${p=>!!p.right && `
        background-position: left -12px bottom;
        background-image: url(${ImgCornerBgRight});
    `}
    .inner {
        width: 48px;
        height: 48px;
        background-repeat: no-repeat;
        
        ${p=>!!p.left && `
            background-position: right -4px bottom -2px;
            background-image: url(${ImgCornerLeftInactive});
        `}

        ${p=>!!p.left && !!p.active && `
            background-image: url(${ImgCornerLeftActive});
        `}

        ${p=>!!p.right && `
            background-position: left -4px bottom -2px;
            background-image: url(${ImgCornerRightInative});
        `}

        ${p=>!!p.right && !!p.active && `
            background-image: url(${ImgCornerRightActive});
        `}


    }
`;
const Corner = (props) =>{
    return (
        <CornerStyle {...props}>
            <div className="inner"/>
        </CornerStyle>
    )
}




const GameGroupsNav = (props) => {
    const [{ matchGroups, activeMatchGroup }, dispatch] = useContext(StateContext);

    return (
        <Style {...props}>
            <Corner left active={activeMatchGroup===0} />
            { matchGroups.map((matchGroup,i) => {
                //let status = 'future';
                let label = i+1;
                //if(i < activeMatchGroup) status = 'past';
                //if(i === activeMatchGroup) {
                    // status = 'active';
                    // label = new Date(matchGroup.elapsed * 1000).toISOString().substr(14, 5);
                    // label = matchGroup.elapsed.toHHMMSS();

                if(matchGroup.status==='started' || matchGroup.status==='finished'){

                    
                    if (matchGroup.elapsed > 60 * 60) {
                        label = moment.utc(moment.duration(matchGroup.elapsed, "seconds").asMilliseconds()).format("H:mm:ss")
                    } else {
                        
                        label = moment.utc(moment.duration(matchGroup.elapsed, "seconds").asMilliseconds()).format("m:ss")
                    }
                }
                    //}
                
                return (
                    <Button status={matchGroup.status} first={i===0} last={i===matchGroups.length-1}>
                        {label}
                    </Button>
                )
            }) }
            <Corner right active={activeMatchGroup===matchGroups.length-1} />
        </Style>
    )
}

export default GameGroupsNav