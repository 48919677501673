import { useContext, useEffect, useMemo, useState } from "react";
// import useTimeout from "../../hooks/useTimeout";

import { useSpring, animated as a } from 'react-spring'
import { StateContext } from "../../App";

const { default: styled } = require("@emotion/styled");

const Style = styled.div`
    background-color: #fff;
    border-radius: 18px;
    box-shadow: 4px 4px 0 0px #a59282;
    height: 148px;
    width: 99px;
    border: 10px solid #fff;
    position: relative;




    ${p => ['optionCard', 'answerCard'].includes(p.type) && `
        height: 210px;
        width: 140px;
        
    `}
    ${p => ['answerCard'].includes(p.type) && !p.shown  &&  `
        background-color: #E5E5F7;
        opacity: 0.4;
        background-image:  linear-gradient(135deg, #444CF7 25%, transparent 25%), linear-gradient(225deg, #444CF7 25%, transparent 25%), linear-gradient(45deg, #444CF7 25%, transparent 25%), linear-gradient(315deg, #444CF7 25%, #E5E5F7 25%);
        background-position:  20px 0, 20px 0, 0 0, 0 0;
        background-size: 20px 20px;
        background-repeat: repeat;
    `}
    ${p => p.type==='optionCard' && !p.shown && `
        background-image: linear-gradient(165deg, #49beff 32%, #8048ff 101%);
        cursor: pointer;
        user-select: none;
        `}
    ${p => p.type==='optionCard' && p.matched && `
        background-color: #fff;
        background-image: none;
        opacity: 0;
    `}
    ${p => p.cardSize==='smallCard' && `
        height: 172px;
        width: 115px;
    `}
`;

const Photo = styled.div`
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    min-height: 185px;
    background-size: cover;
    line-height: 34px;
    max-width: 100%;

    ${p => p.type==='listCard' && `
        min-height: 132px;
        width: 140px;
    `}
`

const Caption = styled.div`
    text-align: center;
    color: #34365e;
    font-size: 24px;

    ${p => p.type==='listCard' && `
        font-size: 16px;
        line-height: 26px;
    `}
`;

const CenteredChar = styled.div`
    text-align: center;
    font-size: 98px;
    line-height: 210px;
`
const Cheat = styled.div`
    position: absolute;
    bottom: 20px;
    right: 20px;
`

const Card = ({id, img, name, shown, type, toggle, matched}) => {
    const [{cheat}] = useContext(StateContext);
    const [{optionCardCoverType}] = useContext(StateContext);
    const [{answerCardCoverType}] = useContext(StateContext);
    const [{matchesPerGroup}] = useContext(StateContext);

    let emojiAnswerCard = '🙈';
    let emojiOptionCard = '👻';

    switch(answerCardCoverType) {
        default: 
            // emojiAnswerCard = '🙈';
            emojiAnswerCard = '';

    }
    switch(optionCardCoverType) {
        case 1: 
            emojiOptionCard = '👻'
            break;
        case 2: 
            emojiOptionCard = '🚂'
            break;
        case 3: 
            emojiOptionCard = '🥒'
            break;
        case 4: 
            emojiOptionCard = '🍎'
            break;
        case 5: 
            emojiOptionCard = '🌋'
            break;
        case 6: 
            emojiOptionCard = '🚗'
            break;
        case 7: 
            emojiOptionCard = '🐠'
            break;
        case 8: 
            emojiOptionCard = '📬'
            break;
        case 9: 
            emojiOptionCard = '🍌'
        case 10: 
            emojiOptionCard = '🍁'
            break;
        default: 
            emojiOptionCard = '🍎';

    }

    // console.log("emoji",emoji)


    const flipped = useMemo(()=>{
        return !(shown || matched);
    },[shown || matched]);

    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 }
    })


    const cardSize = matchesPerGroup > 6 ? 'smallCard' : null;
    // console.log("cardSize",cardSize);
    
    return (
        <Style onClick={toggle} cardSize={cardSize} type={type} shown={shown} matched={matched} >

            {/* <a.div class="c back" style={{ position: 'relative',  opacity: opacity.interpolate(o => 1 - o), transform }} > */}
            <a.div class="c back" style={{ position: 'absolute', width: `140px`, maxWidth:'100%',  opacity: opacity.interpolate(o => 1 - o), transform }} >
                <Photo type={type} style={{backgroundImage: `url(${img})`}}/>
                <Caption type={type}>{name}</Caption>
            </a.div>
            {/* <a.div class="c front" style={{ position: 'relative', opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}> */}
            <a.div class="c front" style={{ position: 'absolute', width: `140px`, opacity, transform: transform.interpolate(t => `${t} rotateY(180deg)`) }}>
                <CenteredChar>{type=='optionCard'? emojiOptionCard : emojiAnswerCard}{cheat?<Cheat>{id}</Cheat>:null}</CenteredChar>
            </a.div>
            
        </Style>
    )
}

export default Card;