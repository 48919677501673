import styled from "@emotion/styled";
import { useContext } from "react";
import { StateContext } from "../../App";
import PlayGameButton from "./PlayGameButton";
import GameGroupsNav from "./GameGroupsNav";
import bgLeftBtn from '../../images/bg-left-btn.svg'
import leftBtn from '../../images/left-btn.svg'
import bgRightBtn from '../../images/bg-right-btn.svg'
import rightBtn from '../../images/right-btn.svg'
import iconHelp from '../../images/help.svg'
import iconSettings from '../../images/settings.svg'
import iconStop from '../../images/stop.svg'

import useSound from 'use-sound';

import boopSfx from '../../sounds/button_on_off_014.mp3';

// https://www.soundsnap.com/search/audio/button%20press/score/2sec


const Style = styled.div`
    min-height: 108px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
`;

const CornerSection = styled.div`
    position: relative;
    display: flex;
    min-width: 230px;
    ${p => p.left && `
        background-image: url(${bgLeftBtn});
        justify-content: flex-start;
        `}
    ${p => p.right && `
        background-image: url(${bgRightBtn});
        justify-content: flex-end;
        `}
    height: 51px;
    align-self: flex-end;
    padding-top: 10px;
   
    
    button {
        ${p => p.left && `
            background: transparent url(${leftBtn});
            padding-left: 40px;
            `}
        ${p => p.right && `
            background: transparent url(${rightBtn});
            padding-right: 40px;
            `}
        padding-bottom: 9px;
        min-width: 220px;
        height: 62px;
        border: none;
        font-size: 28px;
        text-align: center;
        color: #fff;
        &:focus {
            /* box-shadow: 0 0 0 8px #7d7c9a, 0 2px 4px 0 rgba(0, 0, 0, 0.25) */
            box-shadow: 0 0 0 0px #7d7c9a, 0 0px 0px 0 rgba(0, 0, 0, 0.25)
        }
    }

    img.icon {
        position: absolute;
        bottom: 5px;
        ${p => p.left && `
            left: 11px;
        
        `}
        ${p => p.right && `
            right: 5px;
            
            `}

        opacity: 0.5;
    }
`;


const RightSection = styled.div`
    min-width: 230px;
`;

const CenterSection = styled.div`
    display: flex;
    align-items: flex-start;
`;

const BlueButton = styled.button`
    color: #fff;
    background-image: linear-gradient(to bottom, #33ccff, #6633ff);
    line-height: 62px;
    padding: 0 20px;
`;

const BottomNav = () => {
    const [{started}, dispatch] = useContext(StateContext);

    const [play] = useSound(boopSfx);


    const startGame = (e) => {
        play();
        dispatch({type:'START_GAME'});
    }
    const stopGame = e => dispatch({type:'STOP_GAME'});
    
    return (
        <Style>
            <CornerSection left>
                {started ? 
                <>
                    <img className="icon" src={iconStop}/>
                    <button onClick={stopGame}>Stop</button>
                </>
                :
                <>
                    <img className="icon" src={iconHelp}/>
                    <button>About</button>
                </>
                }
            </CornerSection>
            <CenterSection>
                {started ? 
                <GameGroupsNav />
                :
                <PlayGameButton onClick={startGame}/>
            }
            </CenterSection>
            <CornerSection right>
                <img className="icon" src={iconSettings}/>
                <button>Level 1</button>
            </CornerSection>
        </Style>
    )
}

export default BottomNav