import styled from "@emotion/styled";
import { useContext } from "react";
import { StateContext } from "../../App";
import moment from 'moment';

import {useWindowSize} from 'react-use';
import Confetti from 'react-confetti'


const Container = styled.div`
    display: flex;
    justify-content: center;
`;

//border: 9px solid #f2d9b4;
const Card = styled.div`
    box-shadow:  0 9px 0 0 #aa9079, inset 0 0 0 9px #f2d9b4;
    border-radius: 33.8px;
    min-height: 450px;
    width: 570px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 170px;
    background-color: #fff;
    position: relative;
    color: #34365e;

    p {
        margin: 0;
    }
    
    .large-1 {
        font-size: 36px;
        margin: 20px 0 25px;
    }
    .large-2 {
        font-size: 42px;
        margin-top: 23px;
    }
    .large-3 {
        font-size: 54px;
    }

    .ghost {
        font-size: 67px;
    }
    
    `;

const CloseButton = styled.div`
    position: absolute;
    width: 30px;
    height: 30px;
    top: 27px;
    right: 30px;
    font-size: 54px;
    line-height: 27px;
    color: #836033;
    cursor: pointer;
    user-select: none;
`;


const TopIcon = styled.div`
    position: absolute;
    width: 200px;
    height: 200px;
    top: -100px;
    left: 50%;
    margin-left: -100px;
    font-size: 135px;
    color: #836033;
    cursor: pointer;
    user-select: none;
    text-align: center;
    line-height: 210px;
`;



const PlayButton = styled.button`
    position: absolute;
    width: 250px;
    height: 70px;
    bottom: -35px;
    left: 50%;
    margin-left: -125px;

    color: #fff;
    font-size: 32px;

    
    
    cursor: pointer;
    user-select: none;
    text-align: center;

    border-radius: 34.9px;
    box-shadow:0 0 0 9px #f2d9b4, 0 16px 0 0 #aa9079, 0 2px 5px 0 rgba(0,0,0,0.25);
    background-image: linear-gradient(to bottom, #33ccff, #6633ff);
`;




const GameOver = () => {
    const [{elapsed}, dispatch] = useContext(StateContext);

    const { width, height } = useWindowSize()
  

    let elapsedFormatted = null;
    if (elapsed > 60 * 60) {
        elapsedFormatted = moment.utc(moment.duration(elapsed, "seconds").asMilliseconds()).format("H:mm:ss")
    } else {
        
        elapsedFormatted = moment.utc(moment.duration(elapsed, "seconds").asMilliseconds()).format("m:ss")
    }

    return <Container>
        <Confetti
      width={width}
      height={height}
    />
        <Card>
            <CloseButton onClick={e => dispatch({type:'STOP_GAME'})}>×</CloseButton>

            <TopIcon>🐵</TopIcon>

            <p className="large-2">Yay!</p>
            <p className="large-3">You did it!</p>
            <p className="large-1">🏆 {elapsedFormatted}</p>

            <p className="ghost">👻</p>
            <PlayButton onClick={e=>{dispatch({type:'STOP_GAME'});dispatch({type:'START_GAME'})}}>Play Again</PlayButton>

        </Card>
        {/* <div>{JSON.stringify(state)}</div> */}
    </Container>
}

export default GameOver