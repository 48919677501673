import React, { createContext, useContext, useReducer } from 'react';
import styled from '@emotion/styled'
import BottomNav from './components/BottomNav/BottomNav';
import CardsGrid from './components/CardsGrid/CardsGrid';
// import Card from './ui/Card/Card';


import appBgImage from './images/background.svg'
import useInterval from './hooks/useInterval';
import GameGrid from './components/GameGrid/GameGrid';
import cards from './cards';


const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 14px;
`;



const H1 = styled.h1`
    text-align: center;
    color: #34365e;
    margin: 0 0 40px 0;
`;

const AppStyles = styled.div`
  min-height: 100vh;
  padding-top: 40px;
  
  background-position-y: -250px;
  
  &:after {
    content: "";
    background-image: url(${appBgImage});
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;  
  }
  
`;

const urlparams = new URLSearchParams(window.location.search)



const initialGame = {
  id: '',
  cheat: !!urlparams.get('cheat'),
  optionCardCoverType: +urlparams.get('optionCardCoverType'),
  answerCardCoverType: +urlparams.get('answerCardCoverType'),
  entered: Date.now(),
  started: null, // Date
  finished: null, // Date
  elapsed: 0, // game length
  totalAvailableMatchCount: 0, // total kids
  matchesPerGroup: 0, // 5 kids -> 3 matches
  matchGroups: [
    /*
    {
      status: 'not-started', 'partial', 'completed'
      elapsed: 0,
      matchedIDs: [],
      remainingMatchIDs: [],
      possibleMatchCount: 0,
      attempts: 0,
    }
    */
  ],
  activeMatchGroup: 0
};


function getCardIdByGridPositionIndex(matchCards, gridPositionIndex){
  let matchCardAnswerIndex = -1;
  for(let matchCard of matchCards){
    matchCardAnswerIndex += 1;
    for(let matchCardIndex = 0; matchCardIndex < matchCard.gridPositions.length; matchCardIndex++ ){
      const gridPosition = matchCard.gridPositions[matchCardIndex];
      //for(let gridPosition of matchCard.gridPositions){
      // console.log("get card Id, gridPositionIndex=",gridPositionIndex, " gridPosition",gridPosition)
      if(gridPositionIndex===gridPosition){
        // console.log("FOUND matchCard=",matchCard," matchCaardIndex=",matchCardIndex)
        return {matchCard, matchCardAnswerIndex};
      }
    }
  }
}


const reducer = (state, action)=> {



  switch(action.type){
    case "START_GAME":
      {
        const matchesPerGroup = (()=>{
          const difficulty = urlparams.get('difficulty');
          if(difficulty) return +difficulty;
          return 4; // level of difficulty that the user selects, default to 3 cards for now;
        })();

        const totalAvailableMatchCount = cards.length;
        const matchGroupsCount = 1 + totalAvailableMatchCount / matchesPerGroup;
        let cardIds = cards.map(c => c.id);


        const matchGroups = Array.from({length: matchGroupsCount}, (x, i)=>{

          // generate array of indexes based on the cards length
          let gridPositionIndexes = Array.from({length: matchesPerGroup * 2 }, (x, i) => i);
          // randomize the array
          gridPositionIndexes = shuffle(gridPositionIndexes);
          
          //let remainingMatchIDs = [];
          let matchCards = [];
          for(let i=0; i < matchesPerGroup; i++) {
            if(!cardIds.length) {
              break;
            }
            const randomIndex = Math.floor(Math.random() * cardIds.length);
            // matchIDs.push(cardIds[randomIndex]);
            matchCards.push(
              {
                id: cardIds[randomIndex],
                matched: false,
                gridPositions: [gridPositionIndexes.pop(), gridPositionIndexes.pop()], // indexes of where the cards are on the grid
              }
              );
              cardIds.splice(randomIndex, 1);
            }
            
            return {
              status: i===0 ? 'started' : 'not-started',
              elapsed: 0,
              matchCards,
              attempts: 0,
              openGridPositonsIndexes: [], // index of a card within a group that the player opened
            //matchedIDs: [],
            //remainingMatchIDs,
            //possibleMatchCount: 0,
            //answerRowIds: [...remainingMatchIDs], //3, 1, 5
            //choiceOrderIndexIds: [...matchCards.map(c=>c.id), ...matchCards.map(c=>c.id)], // 1
          }
        });

        console.log('matchGroups:', JSON.stringify(matchGroups))

        return {...state, 
          started: Date.now(),
          totalAvailableMatchCount,
          matchesPerGroup,
          matchGroups: matchGroups.filter(matchGroup => matchGroup.matchCards.length!==0),
        }
    }

    case "CARD_SELECTED": {
      console.log('CARD_SELECTED case')


      let activeMatchGroup = state.activeMatchGroup;
      let finished = state.finished;

      const matchGroups = state.matchGroups.map((matchGroup, matchGroupIndex)=> {
        if(matchGroupIndex === state.activeMatchGroup){


          const openGridPositonsIndexes = [ ...matchGroup.openGridPositonsIndexes,  action.gridPositionIndex];

          if(openGridPositonsIndexes.length === 1) {
            // new attempt, simply set the requested card to open
            return {...matchGroup, openGridPositonsIndexes }
          }

          if(openGridPositonsIndexes.length === 2) {

            // if the click the same card -- ignore
            if(openGridPositonsIndexes[0] === openGridPositonsIndexes[1]){
              return {...matchGroup,
                openGridPositonsIndexes: [openGridPositonsIndexes[0]]
              }
            }

            
            // compare the two cards. Do they match?
            // Find the cards by indexes and find their IDs
            const {matchCard: matchCardOne, matchCardAnswerIndex} = getCardIdByGridPositionIndex(matchGroup.matchCards, openGridPositonsIndexes[0]);
            const {matchCard: matchCardTwo} = getCardIdByGridPositionIndex(matchGroup.matchCards, openGridPositonsIndexes[1]);



            if(matchCardOne.id === matchCardTwo.id){
              // two cards win! find the matchCard and set "matched" to true

              const nextMatchGroup = {...matchGroup,
                openGridPositonsIndexes: [],
                matchCards: matchGroup.matchCards.map((matchCard,i) => {
                  if(i===matchCardAnswerIndex){
                    return { ...matchCard, matched: true }
                  }
                  return matchCard;
                })
              }

              const anyUnmatchedCardsLeft = nextMatchGroup.matchCards.find(matchCard => !matchCard.matched);
              if(!anyUnmatchedCardsLeft){
                // match group win!
                activeMatchGroup++;
              }


              
              return nextMatchGroup;
            } else {
              return {...matchGroup,
                openGridPositonsIndexes,
                attempts: matchGroup.attempts+1
                //openGridPositonsIndexes: []
              }
            }


          }





          // matchGroup.openGridPositonsIndexes -- is it a number? then player has already opened a card
          // if( typeof matchGroup.openGridPositonsIndexes === 'number'){
          //   if( matchGroup.openGridPositonsIndexes === action.gridPositionIndex ){
          //     // same card clicked, do nothing
          //     return matchGroup;
          //   }
          //   // compare the previously open card to this new 
          // }
          

          return {...matchGroup,
            openGridPositonsIndexes,
          }
        }
        return matchGroup;
      });

      if(activeMatchGroup > matchGroups.length-1){
        finished = Date.now();
      }


      return {...state, activeMatchGroup, finished, 
        matchGroups: matchGroups.map((matchGroup,i) => {
        let status = 'not-started';
        if(i===activeMatchGroup){
          status = 'started'
        } else if(i<activeMatchGroup){
          status = 'finished';
        }
        return {...matchGroup, status }
      }) }
    }
    
    
    case "CLEAR_SELECTED_CARDS": {
      let activeMatchGroup = state.activeMatchGroup;
      let finished = state.finished;
      
      const matchGroups = state.matchGroups.map((matchGroup, matchGroupIndex)=> {
        if(matchGroupIndex === state.activeMatchGroup){
          return {...matchGroup, openGridPositonsIndexes: [] };
        }
        return matchGroup;
      });
      return {...state, matchGroups };
    }
    

    case "TIME_TICK": {
      return {...state,
        elapsed: state.elapsed+1,
        matchGroups: [
          ...state.matchGroups.slice(0, state.activeMatchGroup),
          {...state.matchGroups[state.activeMatchGroup],
            elapsed: state.matchGroups[state.activeMatchGroup].elapsed + 1
          },
          ...state.matchGroups.slice(state.activeMatchGroup + 1),
        ]
      }

    }

    case "STOP_GAME": {
      return initialGame
    }

    case "SET":
      return {...state, ...action.payload};
    default:
      return state;
  }
}

export const StateContext = createContext(initialGame);

const StateProvider = ({children}) => {
  const value = useReducer(reducer, initialGame);
  return (
    <StateContext.Provider value={value}>
      {children}
    </StateContext.Provider>
  )
}




const App = () => {


  const [state, dispatch] = useContext(StateContext);
  const { started, finished } = state;

  useInterval(() => {
    dispatch({type:'TIME_TICK'})
  }, started&&!finished ? 1000 : null);
  
  return (
      <AppStyles>


        { started ?
        <GameGrid />
        :
        <>
        <H1>West AM Faces, 2020-21</H1>
        <CardsGrid />
        </>
        }


        <BottomNav/>
      </AppStyles>
  );
}

const AppWithState = () => {
  return (
    <StateProvider>
      <App/>
    </StateProvider>
  );
}

export default AppWithState;




function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}