import styled from "@emotion/styled";
import Card from '../../ui/Card/Card';
import cards from '../../cards';
import { useContext, useMemo, useState } from "react";
import { StateContext } from "../../App";
import GameOver from "../GameOver/GameOver";
import useTimeout from "../../hooks/useTimeout";
import { isElementOfType } from "react-dom/test-utils";
import useSound from 'use-sound';

import sndadhiraj from '../../sounds/kids/arnold.m4a';
import sndanna from '../../sounds/kids/anna.m4a';
import sndarnold from '../../sounds/kids/arnold.m4a';
import sndbobby from '../../sounds/kids/bobby.m4a';
import sndcharlie from '../../sounds/kids/charlie.m4a';
import sndelizabeth from '../../sounds/kids/elizabeth.m4a';
import sndemory from '../../sounds/kids/emory.m4a';
import sndgeorge from '../../sounds/kids/george.m4a';
import sndgrace from '../../sounds/kids/grace.m4a';
import sndgraydon from '../../sounds/kids/graydon.m4a';
import sndjohnny from '../../sounds/kids/johnny.m4a';
import sndjoshua from '../../sounds/kids/joshua.m4a';
import sndjuliette from '../../sounds/kids/juliette.m4a';
import sndkaitlyn from '../../sounds/kids/kaitlyn.m4a';
import sndkiara from '../../sounds/kids/kiara.m4a';
import sndkristina from '../../sounds/kids/kristina.m4a';
import sndluna from '../../sounds/kids/luna.m4a';
import sndolek from '../../sounds/kids/olek.m4a';
import sndoliver from '../../sounds/kids/oliver.m4a';
import sndrebeca from '../../sounds/kids/rebeca.m4a';
import sndroselyn from '../../sounds/kids/roselyn.m4a';
import sndrowan from '../../sounds/kids/rowan.m4a';
import sndsky from '../../sounds/kids/sky.m4a';
import sndzane from '../../sounds/kids/zane.m4a';

import sndCardFlip1 from '../../sounds/420683-Card-Game-Movement-Deal-Single-Small-01.mp3';
import sndCardFlip2 from '../../sounds/420684-Card-Game-Movement-Deal-Single-Small-02.mp3';
import sndCardMatch from '../../sounds/Vibrant_Game_Correct_Answer_1.mp3';

const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(${p => {
        if(p.cols) {
            if(p.cols>6) return 6;
            return p.cols;
        }
        return 5;
    }}, 1fr);
    grid-gap: 14px;

    margin: 0 auto;
`;
const Item = styled.div`

`;

const Flex = styled.div`
    display: Flex;

    margin: 0 auto 40px;
    & > * {
        margin: 0 7px;
    }
`;




const GameGrid = () => {
    const [playAdhirajSound] = useSound(sndadhiraj);
    const [playAnnaSound] = useSound(sndanna);
    const [playArnoldSound] = useSound(sndarnold);
    const [playBobbySound] = useSound(sndbobby);
    const [playCharlieSound] = useSound(sndcharlie);
    const [playElizabethSound] = useSound(sndelizabeth);
    const [playEmorySound] = useSound(sndemory);
    const [playGeorgeSound] = useSound(sndgeorge);
    const [playGraceSound] = useSound(sndgrace);
    const [playGraydonSound] = useSound(sndgraydon);
    const [playJohnnySound] = useSound(sndjohnny);
    const [playJoshuaSound] = useSound(sndjoshua);
    const [playJulietteSound] = useSound(sndjuliette);
    const [playKaitlynSound] = useSound(sndkaitlyn);
    const [playKiaraSound] = useSound(sndkiara);
    const [playKristinaSound] = useSound(sndkristina);
    const [playLunaSound] = useSound(sndluna);
    const [playOlekSound] = useSound(sndolek);
    const [playOliverSound] = useSound(sndoliver);
    const [playRebecaSound] = useSound(sndrebeca);
    const [playRoselynSound] = useSound(sndroselyn);
    const [playRowanSound] = useSound(sndrowan);
    const [playSkySound] = useSound(sndsky);
    const [playZaneSound] = useSound(sndzane);
    const [playCardOneFlippedSound] = useSound(sndCardFlip1);
    const [playCardTwoFlippedSound] = useSound(sndCardFlip2);
    const [playCardMatchedSound] = useSound(sndCardMatch);
    const [state, dispatch] = useContext(StateContext);
    const { activeMatchGroup, matchGroups, finished } = state;
    const matchGroup = matchGroups[activeMatchGroup];    /*
            {
                status: 'not-started',
                elapsed: 0,
                matchCards,
                attempts: 0,
            }
    */
    //const [shownCardIds, setShownCardIds] = useState([]);

    const playKidsNameSound = (id) => {

        switch (id) {
            case 1: 
                return playAdhirajSound();
            case 2: 
                return playAnnaSound();
            case 3: 
                return playArnoldSound();
            case 4: 
                return playBobbySound();
            case 5: 
                return playCharlieSound();
            case 6: 
                return playElizabethSound();
            case 7: 
                return playEmorySound();
            case 8: 
                return playGeorgeSound();
            case 9: 
                return playGraceSound();
            case 10: 
                return playGraydonSound();
            case 11: 
                return playJohnnySound();
            case 12: 
                return playJoshuaSound();
            case 13: 
                return playJulietteSound();
            case 14: 
                return playKaitlynSound();
            case 15: 
                return playKiaraSound();
            case 16: 
                return playKristinaSound();
            case 17: 
                return playLunaSound();
            case 18: 
                return playOlekSound();
            case 19: 
                return playOliverSound();
            case 20: 
                return playRebecaSound();
            case 21: 
                return playRoselynSound();
            case 22: 
                return playRowanSound();
            case 23: 
                return playSkySound();
            case 24: 
                return playZaneSound();
            default: 
                return null;
        }
        

    }


    const shownCardIndexes = useMemo(()=>{
        if(!matchGroup) return [];
        let shownCardIndexes = [...matchGroup.openGridPositonsIndexes];
        return shownCardIndexes;
    },[matchGroup?.openGridPositonsIndexes]);

    if(finished) {
        return <GameOver />
    }


    
    let orderedCards = [];
    matchGroup.matchCards.forEach(matchCard => {

        matchCard.gridPositions.forEach(index => {
            orderedCards[index] = matchCard;
        })
    });



    return (
        <Container>
            <Flex>
                {matchGroup.matchCards.map((card,gridPositionIndex) => {
                    // const shown = false;
                    const shown = card.matched;
                    const cardProps = {
                        ...card, ...cards.find(c=>c.id===card.id),
                        shown,
                        type: 'answerCard'
                    };
                    return (
                        <Card {...cardProps} />
                    )
                })}
            </Flex>
            <Grid cols={matchGroup.matchCards.length}>
                {orderedCards.map((card,gridPositionIndex) => {
                    const shown = false;
                    const cardProps = {
                        ...card, 
                        ...cards.find(c=>c.id===card.id),
                        shown: shownCardIndexes.includes(gridPositionIndex),
                        isSecondGuess: shownCardIndexes.findIndex(v => v===gridPositionIndex)===1,
                        type: 'optionCard',
                        toggle: e => {
                            shownCardIndexes.length===0 ? playCardOneFlippedSound() : playCardTwoFlippedSound();
                            playKidsNameSound(card.id);

                            dispatch({type:'CARD_SELECTED', gridPositionIndex })
                  
                        }
                    };

                    return (
                        <Card {...cardProps}/>
                    )
                })}
            </Grid>
            {shownCardIndexes.length===2 ? 
            <Cover />
             :null}
        </Container>
    );
}


const CoverStyle = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    /* background-color: rgba(255,255,255,0.5); */
    background-color: rgba(255,255,255,0);
`;

const Cover = () => {
    const [state, dispatch] = useContext(StateContext);
    // timeout: clear openGridPositonsIndexes


    useTimeout(()=>{
        console.log('setFailedMatchAttempt(false)');

        dispatch({type:'CLEAR_SELECTED_CARDS' })

    }, 2000);


    return (
        <CoverStyle>

        </CoverStyle>
    )
}

export default GameGrid;
