import styled from "@emotion/styled"
import playIcon from "../../images/play.svg"


const Style = styled.button`
    color: #fff;
    background-image: linear-gradient(to bottom, #33ccff, #6633ff);
    line-height: 62px;
    padding: 0 20px;
    border-radius: 100px;
    box-shadow: 0 0 0 8px #dcd2d1, 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    font-size: 28px;
`;

const PlayGameButton = (props) => {



    return (
        <Style {...props}>
            <img src={playIcon} />
            Play Game</Style>
    )
}

export default PlayGameButton